

import { Component, Vue, Prop } from 'vue-property-decorator';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';

@Component({
    components: {
        Occupancy,
    },
})

export default class FleetOccupancy extends Vue {
    @Prop({ required: true })
    private data!: {
        availableCars: number,
        fleetSize: number,
    };

    get occupancy(): number | null {
        const { availableCars, fleetSize } = this.data;
        if (!fleetSize) {
            return 0;
        }
        return Math.round((Number(availableCars) * 100) / Number(fleetSize));
    }
}
